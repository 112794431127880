<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        ref="form"
        @submit="saveData"
        :initialValues="initialValues"
        class="w-full pr-2"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >animal addendum
            </label>
          </div>
        </div>
        <div>
          <label class="form-section-sub-title"
            >Additional Charges For Pets</label
          >
          <div class="form-row">
            <TextField
              name="additionalPetSecurityDeposit"
              label="Additional Pet Security Deposit"
            /><TextField
              name="additionalPetMonthlyRent"
              label="Additional Pet Monthly Rent"
            />
            <TextField
              name="additionalPetOneTimeFee"
              label="Additional Pet (one-time) Fee"
            />
          </div>

          <div class="h-4"></div>
          <label class="form-section-sub-title"
            >PET INFORMATION (If Applicable)
          </label>

          <div class="text-title">pet 1:</div>
          <div class="h-4"></div>
          <div class="form-row">
            <TextField name="pet1.name" label="animals name" />
            <TextField name="pet1.type" label="type" />
            <TextField
              name="pet1.breed"
              label="breed"
            />
          </div>
          <div class="form-row">
            <TextField name="pet1.color" label="color" />
            <TextField
              name="pet1.cityOfLicence"
              label="city of licence"
            />
            <TextField
              name="pet1.rabisShotDate"
              type="date"
              label="date of last rabies shot"
            />
          </div>
          <div class="form-row">
            <TextField
              name="pet1.houseBroken"
              label="housebroken"
            />
            <TextField
              name="pet1.ownerName"
              label="animal owner's name"
            />
            <TextField name="pet1.weight" type="number" label="weight" />
            <TextField name="pet1.age" type="number" label="age" />
          </div>
          <div class="h-4"></div>
          <div class="text-title">pet 2:</div>
          <div class="h-4"></div>
          <div class="form-row">
            <TextField name="pet2.name" label="animals name" />
            <TextField name="pet2.type" label="type" />
            <TextField
              name="pet2.breed"
              label="breed"
            />
          </div>
          <div class="form-row">
            <TextField name="pet2.color" label="color" />
            <TextField
              name="pet2.cityOfLicence"
              label="city of licence"
            />
            <TextField
              name="pet2.rabisShotDate"
              type="date"
              label="date of last rabies shot"
            />
          </div>
          <div class="form-row">
            <TextField
              name="pet2.houseBroken"
              label="housebroken"
            />
            <TextField
              name="pet2.ownerName"
              label="animal owner's name"
            />
            <TextField name="pet2.weight" type="number" label="weight" />
            <TextField name="pet2.age" type="number" label="age" />
          </div>
          <div class="h-4"></div>
          <div class="text-title">pet 3:</div>
          <div class="h-4"></div>
          <div class="form-row">
            <TextField name="pet3.name" label="animals name" />
            <TextField name="pet3.type" label="type" />
            <TextField
              name="pet3.breed"
              label="breed"
            />
          </div>
          <div class="form-row">
            <TextField name="pet3.color" label="color" />
            <TextField
              name="pet3.cityOfLicence"
              label="city of licence"
            />
            <TextField
              name="pet3.rabisShotDate"
              type="date"
              label="date of last rabies shot"
            />
          </div>
          <div class="form-row">
            <TextField
              name="pet3.houseBroken"
              label="housebroken"
            />
            <TextField
              name="pet3.ownerName"
              label="animal owner's name"
            />
            <TextField name="pet3.weight" type="number" label="weight" />
            <TextField name="pet3.age" type="number" label="age" />
          </div>

          <div class="h-4"></div>
          <label class="form-section-sub-title"
            >SPECIAL PROVISIONS FOR PETS
          </label>
          <div class="form-row">
            <text-input
              id="provision"
              v-model="specialProvision"
              rows="5"
              multiline
            />
          </div>

          <div class="h-4"></div>
          <label class="form-section-sub-title">EMERGENCY</label>
          <div class="form-row">
            <TextField name="emergency.doctor" label="doctor" />
            <TextField name="emergency.address" label="address" />
          </div>
          <div class="form-row">
            <TextField
              name="emergency.citystate"
              label="city/state"
            />
            <TextField name="emergency.phone" label="phone" />
          </div>

          <div class="h-4"></div>
          <label class="form-section-sub-title">PET RULES</label>
          <label class="text-title"
            >pet may urinate or defecate only in these areas:</label
          >
          <div class="h-4"></div>
          <div class="form-row">
            <TextField name="petRules.inside" label="inside" />
            <TextField name="petRules.outside" label="outside" />
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import TextField from "@/components/form/TextField";
import { FinalForm } from "vue-final-form";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextInput from '@/components/ui/TextInput';

export default {
  mixins: [ModalNavigation, InitializeFormMixin],
  components: {
    Loader,
    TextField,
    FinalForm,
    ModalFooter,
    TextInput,
  },
  data() {
    return {
      additionalPetSecurityDeposit: "",
      additionalPetMonthlyRent: "",
      additionalPetOneTimeFee: "",
      pet1: {
        name: "",
        type: "",
        breed: "",
        color: "",
        weight: "",
        age: "",
        cityOfLicence: "",
        licenceNo: "",
        rabisShotDate: "",
        houseBroken: "",
        ownerName: "",
      },
      pet2: {
        name: "",
        type: "",
        breed: "",
        color: "",
        weight: "",
        age: "",
        cityOfLicence: "",
        licenceNo: "",
        rabisShotDate: "",
        houseBroken: "",
        ownerName: "",
      },
      pet3: {
        name: "",
        type: "",
        breed: "",
        color: "",
        weight: "",
        age: "",
        cityOfLicence: "",
        licenceNo: "",
        rabisShotDate: "",
        houseBroken: "",
        ownerName: "",
      },
      specialProvision: "",
      emergency: {
        doctor: "",
        phone: "",
        address: "",
        citystate: "",
      },
      petRules: {
        inside: "",
        outside: "",
      },
      footer: {
        primaryButton: "save",
      },
    };
  },
  computed: {
    initialValues() {
      return {
        additionalPetSecurityDeposit: "",
        additionalPetMonthlyRent: "",
        additionalPetOneTimeFee: "",
        pet1: {
          name: "",
          type: "",
          breed: "",
          color: "",
          weight: "",
          age: "",
          cityOfLicence: "",
          licenceNo: "",
          rabisShotDate: "",
          houseBroken: "",
          ownerName: "",
        },
        pet2: {
          name: "",
          type: "",
          breed: "",
          color: "",
          weight: "",
          age: "",
          cityOfLicence: "",
          licenceNo: "",
          rabisShotDate: "",
          houseBroken: "",
          ownerName: "",
        },
        pet3: {
          name: "",
          type: "",
          breed: "",
          color: "",
          weight: "",
          age: "",
          cityOfLicence: "",
          licenceNo: "",
          rabisShotDate: "",
          houseBroken: "",
          ownerName: "",
        },
        specialProvision: "",
        emergency: {
          doctor: "",
          phone: "",
          address: "",
          citystate: "",
        },
        petRules: {
          inside: "",
          outside: "",
        },
      };
    },
  },
  methods: {
    saveData() {
      this.$refs.form.formState.values.specialProvision = this.specialProvision;
    },
  },
};
</script>

<style scoped>
input {
  accent-color: var(--highlightColor500) !important;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>

